import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useAuthContext } from 'src/auth/hooks';
import Logo from 'src/components/logo';
import { globalConfig } from 'src/config-global';
import { usePathname, useRouter, useSearchParams } from 'src/routes/hooks';
import { LanguagePopover } from './_common';

function AuthLayout({ children }) {
  const router = useRouter();

  const pathname = usePathname();

  const { authenticated, logout } = useAuthContext();

  const searchParams = useSearchParams();
  const referralId = searchParams.get('ref');
  if (referralId) localStorage.setItem('referralId', referralId);

  const checkReferral = useCallback(() => {
    if (pathname.includes('register') && authenticated) {
      logout();
      router.replace('/register');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, referralId]);

  useEffect(() => {
    checkReferral();
  }, [checkReferral]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={globalConfig.reCaptchaKey} language="en">
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Logo
          sx={{
            zIndex: 9,
            position: 'absolute',
            m: { xs: 2, md: 5 },
          }}
        />

        <Stack
          sx={{
            zIndex: 9,
            position: 'absolute',
            right: 0,
            m: { xs: 2, md: 5 },
          }}
        >
          <LanguagePopover />
        </Stack>

        <Stack
          sx={{
            width: 1,
            mx: 'auto',
            maxWidth: 480,
            px: { xs: 2, md: 4 },
            pt: { xs: 15, md: 20 },
            pb: { xs: 15, md: 0 },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </GoogleReCaptchaProvider>
  );
}

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.node,
};
