import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { localStorageGetItem } from 'src/utils/storage-available';
import { defaultLang } from './config-lang';
import translationEn from './langs/en.json';
import translationKo from './langs/ko.json';
import translationVi from './langs/vi.json';
import translationJa from './langs/ja.json';
import translationPh from './langs/ph.json';
import translationId from './langs/id.json';
import translationTh from './langs/th.json';
import translationZh from './langs/zh.json';
import translationMn from './langs/mn.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      ko: { translations: translationKo },
      vi: { translations: translationVi },
      ja: { translations: translationJa },
      ph: { translations: translationPh },
      id: { translations: translationId },
      th: { translations: translationTh },
      zh: { translations: translationZh },
      mn: { translations: translationMn },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
