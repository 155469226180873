import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  home: icon('ic_home'),
  settings: icon('ic_settings'),
  wallet: icon('ic_wallet'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: t('051'),
            path: '/',
            icon: ICONS.home,
          },
          {
            title: t('010'),
            path: '/asset',
            icon: ICONS.home,
            children: [
              { title: t('082'), path: '/asset/overview' },
              { title: t('023'), path: '/asset/history' },
              { title: t('032'), path: '/asset/give' },
              { title: t('047'), path: 'asset/give/history' },
            ],
          },
          {
            title: t('083'),
            path: '/profile',
            icon: ICONS.home,
          },
          {
            title: t('084'),
            path: '/balance',
            icon: ICONS.home,
            children: [
              { title: t('085'), path: '/balance/deposit' },
              { title: t('086'), path: '/balance/withdrawal' },
              { title: t('087'), path: '/balance/deposit/history' },
              { title: t('088'), path: 'balance/withdrawal/history' },
            ],
          },
          {
            title: t('089'),
            path: '/swap',
            icon: ICONS.home,
            children: [
              { title: t('089'), path: '/swap' },
              { title: t('022'), path: '/swap/history' },
              { title: t('090'), path: '/swap/kpaymall' },
              { title: t('091'), path: '/swap/kpaymall/history' },
            ],
          },
          {
            title: t('092'),
            path: '/partner',
            icon: ICONS.home,
            children: [{ title: t('093'), path: '/partner/referral-tree' }],
          },
          {
            title: t('094'),
            path: '/services',
            icon: ICONS.home,
            children: [
              { title: t('075'), path: '/services/notifications' },
              { title: t('095'), path: '/services/news-list' },
            ],
          },
          {
            title: t('096'),
            path: '/support',
            icon: ICONS.home,
            children: [{ title: t('097'), path: '/support/support-tickets' }],
          },
          {
            title: t('072'),
            path: paths.wallet.crypto.root,
            icon: ICONS.wallet,
            children: [
              { title: t('098'), path: paths.wallet.crypto.root },
              { title: t('085'), path: paths.wallet.crypto.deposit },
              { title: t('086'), path: paths.wallet.crypto.withdraw },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
