import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { fDateTime } from 'src/utils/format-time';

const NotificationItem = ({ data }) => {
  const theme = useTheme();
  const { t } = useLocales();

  const open = useBoolean();

  return (
    <>
      <Stack
        component={Paper}
        border={`1px solid ${theme.palette.divider}`}
        p={2}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            background: alpha(theme.palette.grey[500], 0.1),
          },
        }}
        onClick={open.onTrue}
      >
        <Stack direction="row" spacing={1}>
          {data?.isImportant && (
            <Typography variant="subtitle2" color="error">
              {t('074')}
            </Typography>
          )}

          <Stack flex={1}>
            <Typography variant="subtitle1">{data?.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {fDateTime(data?.createdTime)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Dialog fullWidth open={open.value} onClose={open.onFalse}>
        <DialogTitle textAlign="center">{t('075')}</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5}>
            <Typography variant="subtitle1">{data?.title}</Typography>
            <Typography variant="body2">{data?.content}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={open.onFalse}>
            {t('077')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  data: PropTypes.object,
};
