import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useGetNotifications } from 'src/api/notification';
import IconButton from 'src/components/IconButton';
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocales } from 'src/locales';
import NotificationItem from 'src/sections/notification/NotificationItem';
import Header from './header';
import Main from './main';
import NavHorizontal from './nav-horizontal';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();
  const { t } = useLocales();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const notification = useBoolean(true);

  // --- --- --- --- --- --- --- --- --- --- --- ---

  const { data: notifications } = useGetNotifications(true);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>

      <Dialog open={!!notifications.length && notification.value} fullWidth>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">{t('075')}</Typography>
            <IconButton icon="ic:round-close" onClick={notification.onFalse} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} pb={3}>
            {notifications.map((x, i) => (
              <NotificationItem key={i} data={x} />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
