import { lazy, Suspense } from 'react';
import { GuestGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import { AuthLayout } from 'src/layouts';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/Login'));
const RegisterPage = lazy(() => import('src/pages/Register'));
const ForgotPasswordPage = lazy(() => import('src/pages/ForgotPassword'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <AuthLayout>
            <LoginPage />
          </AuthLayout>
        </Suspense>
      </GuestGuard>
    ),
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <AuthLayout>
            <RegisterPage />
          </AuthLayout>
        </Suspense>
      </GuestGuard>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <AuthLayout>
            <ForgotPasswordPage />
          </AuthLayout>
        </Suspense>
      </GuestGuard>
    ),
  },
];
