import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

const CryptoWalletPage = lazy(() => import('src/pages/CryptoWallet'));
const CryptoWalletDepositPage = lazy(() => import('src/pages/CryptoWalletDeposit'));
const CryptoWalletWithdrawPage = lazy(() => import('src/pages/CryptoWalletWithdraw'));

const SettingsPage = lazy(() => import('src/pages/Settings'));

const AssetOverviewPage = lazy(() => import('src/pages/AssetOverview'));
const AssetHistoryPage = lazy(() => import('src/pages/AssetHistory'));
const AssetGivePage = lazy(() => import('src/pages/AssetGive'));
const AssetGiveHistoryPage = lazy(() => import('src/pages/AssetGiveHistory'));

const ProfilePage = lazy(() => import('src/pages/Profile'));

const DepositPage = lazy(() => import('src/pages/Deposit'));
const WithdrawalPage = lazy(() => import('src/pages/Withdrawal'));
const WithdrawalHistoryPage = lazy(() => import('src/pages/WithdrawalHistory'));
const DepositHistoryPage = lazy(() => import('src/pages/DepositHistory'));

const SwapPage = lazy(() => import('src/pages/Swap'));
const SwapHistoryPage = lazy(() => import('src/pages/SwapHistory'));
const SwapKpaymallPage = lazy(() => import('src/pages/SwapKpaymall'));
const SwapKpaymallHistoryPage = lazy(() => import('src/pages/SwapKpaymallHistory'));

const ReferralTreePage = lazy(() => import('src/pages/ReferralTree'));

const NotificationPage = lazy(() => import('src/pages/Notification'));

const SupportTicketsPage = lazy(() => import('src/pages/SupportTickets'));

const NewsListPage = lazy(() => import('src/pages/NewsList'));

const HomePage = lazy(() => import('src/pages/home'));

const WeeklyReportPage = lazy(() => import('src/pages/WeeklyReport'));

const BalanceLogsPage = lazy(() => import('src/pages/BalanceLogs'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <HomePage />, index: true },
      { path: 'weekly-report', element: <WeeklyReportPage /> },
      {
        path: 'asset',
        children: [
          { element: <Navigate to="overview" />, index: true },
          { path: 'overview', element: <AssetOverviewPage /> },
          { path: 'history', element: <AssetHistoryPage /> },
          { path: 'give', element: <AssetGivePage /> },
          { path: 'give/history', element: <AssetGiveHistoryPage /> },
        ],
      },
      { path: 'balance-logs', element: <BalanceLogsPage /> },
      {
        path: 'balance',
        children: [
          { element: <Navigate to="/404" />, index: true },
          { path: 'deposit', element: <DepositPage /> },
          { path: 'withdrawal', element: <WithdrawalPage /> },
          { path: 'withdrawal/history', element: <WithdrawalHistoryPage /> },
          { path: 'deposit/history', element: <DepositHistoryPage /> },
        ],
      },
      {
        path: 'swap',
        children: [
          { element: <SwapPage />, index: true },
          { path: 'history', element: <SwapHistoryPage /> },
          { path: 'kpaymall', element: <SwapKpaymallPage /> },
          { path: 'kpaymall/history', element: <SwapKpaymallHistoryPage /> },
        ],
      },
      {
        path: 'partner',
        children: [
          { element: <Navigate to="/404" />, index: true },
          { path: 'referral-tree', element: <ReferralTreePage /> },
        ],
      },
      {
        path: 'services',
        children: [
          { element: <Navigate to="/404" />, index: true },
          { path: 'notifications', element: <NotificationPage /> },
          { path: 'news-list', element: <NewsListPage /> },
        ],
      },
      {
        path: 'support',
        children: [
          { element: <Navigate to="/404" />, index: true },
          { path: 'support-tickets', element: <SupportTicketsPage /> },
        ],
      },
      { path: 'profile', element: <ProfilePage /> },
    ],
  },
  {
    path: 'wallet',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'crypto',
        children: [
          { element: <CryptoWalletPage />, index: true },
          { path: 'deposit', element: <CryptoWalletDepositPage /> },
          { path: 'withdraw', element: <CryptoWalletWithdrawPage /> },
        ],
      },
    ],
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <SettingsPage />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
  },
];
