import { useCallback, useEffect, useMemo, useState } from 'react';
import endpoints from 'src/services/apiEndpoints';
import fetcher from 'src/services/fetcher';

export const useGetNotifications = (isImportant = false) => {
  const [result, setResult] = useState({});

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const getNotifications = useCallback(() => {
    setIsLoading(true);
    setError(null);
    setResult({});

    fetcher().post(
      endpoints.notification.list,
      { take: 10, fromId: 0, isDesc: true },
      (res) => {
        let { items } = res;
        if (isImportant) {
          items = items.filter((x) => x.isImportant);
        }
        setResult(res);
        setData(items);
        setIsLoading(false);
      },
      (err) => {
        setError(err);
        setIsLoading(false);
      }
    );
  }, [isImportant]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const memoizedValue = useMemo(
    () => ({
      data: data || [],
      loading: isLoading,
      error,
      empty: !isLoading && !data?.length,
      getNotifications,
    }),
    [data, error, getNotifications, isLoading]
  );

  return memoizedValue;
};
