import { useCallback, useEffect, useMemo, useState } from 'react';
import endpoints from 'src/services/apiEndpoints';
import fetcher from 'src/services/fetcher';

export const useGetSupportTickets = () => {
  const [result, setResult] = useState({});

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const getSupportTickets = useCallback(() => {
    setIsLoading(true);
    setError(null);
    setResult({});

    fetcher().post(
      endpoints.support.ticket.list,
      { take: 1000000, fromId: 0, isDesc: true },
      (res) => {
        setResult(res);
        setData(res.items);
        setIsLoading(false);
      },
      (err) => {
        setError(err);
        setIsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    getSupportTickets();
  }, [getSupportTickets]);

  const memoizedValue = useMemo(
    () => ({
      data: data || [],
      loading: isLoading,
      error,
      empty: !isLoading && !data?.length,
      getSupportTickets,
    }),
    [data, error, getSupportTickets, isLoading]
  );

  return memoizedValue;
};
