const endpoints = {
  news: {
    list: '/v1/news/list',
  },

  daily: {
    earn: '/v1/daily-earn/earn',
    history: '/v1/daily-earn/history',
  },

  support: {
    ticket: {
      root: '/v1/support/ticket',
      list: '/v1/support/ticket/list',
    },
    message: {
      root: '/v1/support/message',
      list: '/v1/support/message/list',
    },
  },

  user: {
    profile: '/v1/user/profile',
    login: '/v1/user/login',
    register: '/v1/user/register',
    requestRegisterCode: '/v1/user/send-register-code',
    checkUsername: '/v1/user/check-username',
    checkReferralId: '/v1/user/check-referral',
    requestResetPwdCode: '/v1/user/send-reset-password-code',
    resetPassword: '/v1/user/reset-password',
    password: '/v1/user/password',
    password2: '/v1/user/password2',

    ga: '/v1/user/ga',
    info: '/v1/user/info',
  },

  wallet: {
    cryptoWallet: {
      list: '/v1/wallet/wallets',
      logs: '/v1/wallet/list-logs',
      depositAddress: '/v1/wallet/deposit-address',
      transactions: '/v1/wallet/list-transactions',
      withdraw: '/v1/wallet/withdraw',
    },
  },

  balance: {
    balances: '/v1/balance/balances',
    logs: '/v1/balance/list-logs',
    give: '/v1/balance/give',
    giveHistory: '/v1/balance/give-history',

    transfer: {
      root: '/v1/balance/transfer',
      kpayToWallet: '/v1/balance/transfer-kpay-wallet',
      comToWallet: '/v1/balance/transfer-com-wallet',
      history: '/v1/balance/transfer-history',
    },
  },

  swapInvest: {
    root: '/v1/swap-invest/swap',
    history: '/v1/swap-invest/history',
  },

  notification: {
    list: '/v1/notification/list',
  },

  dashboard: {
    profile: '/v1/dashboard/profile',
    home: '/v1/dashboard/home',
  },
};

export default endpoints;
