import {
  enUS as enUSCore,
  jaJP as jaJPCore,
  koKR as koKRCore,
  viVN as viVNCore,
  zhCN as zhCNCore,
} from '@mui/material/locale';
import {
  enUS as enUSDataGrid,
  jaJP as jaJPDataGrid,
  koKR as koKRDataGrid,
  viVN as viVNDataGrid,
  zhCN as zhCNDataGrid,
} from '@mui/x-data-grid';
import {
  enUS as enUSDate,
  jaJP as jaJPDate,
  koKR as koKRDate,
  viVN as viVNDate,
  zhCN as zhCNDate,
} from '@mui/x-date-pickers/locales';
import {
  enUS as enUSAdapter,
  ja as jaJPAdapter,
  ko as koKRAdapter,
  vi as viVNAdapter,
  zhCN as zhCNAdapter,
} from 'date-fns/locale';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Chinese',
    value: 'zh',
    systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
    adapterLocale: zhCNAdapter,
    icon: 'flagpack:cn',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Filipino',
    value: 'ph',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:ph',
  },
  {
    label: 'Indonesian',
    value: 'id',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:id',
  },
  {
    label: 'Japanese',
    value: 'ja',
    systemValue: merge(jaJPDate, jaJPDataGrid, jaJPCore),
    adapterLocale: jaJPAdapter,
    icon: 'flagpack:jp',
  },
  {
    label: 'Korean',
    value: 'ko',
    systemValue: merge(koKRDate, koKRDataGrid, koKRCore),
    adapterLocale: koKRAdapter,
    icon: 'flagpack:kr',
  },
  {
    label: 'Mongolia',
    value: 'mn',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:mn',
  },
  {
    label: 'Thai',
    value: 'th',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:th',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    systemValue: merge(viVNDate, viVNDataGrid, viVNCore),
    adapterLocale: viVNAdapter,
    icon: 'flagpack:vn',
  },
];

export const defaultLang = allLangs[1]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
